<template>
  <v-card
    id="delivery-note-list"
    class="px-3 py-5">
    <v-row>
      <v-col cols="auto">
        <h6 class="text-h6">
          Delivery Notes
        </h6>
      </v-col>
      <v-spacer />
      <v-col
        :cols="isMobile ? '12' : 'auto'"
        :class="isMobile ? 'text-right' : ''">
        <v-spacer v-if="isMobile"></v-spacer>
        <v-btn
          :to="{
            name: 'DeliveryNote',
            query: {
              warehouse: warehouseSelected
            }
          }"
          target="_blank"
          color="primary"
          class="mr-2"
          :disabled="isDisabled || isDisabledByRole">
          Create Note
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isDisabled || isDisabledByRole"
          @click="togglePODialog()">
          Create By PO
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isMobile ? '6' : 'auto'">
        <WarehouseSelect
          v-model="warehouseSelected"
          :items="warehouses"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          outlined />
      </v-col>
      <v-col :cols="isMobile ? '6' : 'auto'">
        <v-select
          v-model="operation"
          :items="operationItems"
          :menu-props="{ offsetY: true }"
          :class="isMobile ? '' : 'movement-operation'"
          auto-select-first
          hide-details
          solo
          dense
          @change="checkOperation()" />
      </v-col>
      <v-col
        v-if="enableDateRange"
        cols="auto">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-row>
              <v-col cols="auto">
                <v-text-field
                  v-model="dateRangeText[0]"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  class="date-range-input mr-2"
                  max-width="290px"
                  readonly
                  hide-details
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on" />
              </v-col>
              <v-col cols="auto">
                <v-text-field
                  v-model="dateRangeText[1]"
                  label="End Date"
                  class="date-range-input"
                  max-width="290px"
                  readonly
                  hide-details
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on" />
              </v-col>
            </v-row>
          </template>
          <v-date-picker
            v-model="dates[0]"
            class="mr-2 elevation-3"
            :max="$dayjs(new Date()).format('YYYY-MM-DD')"
            @input="checkOperation()" />
          <v-date-picker
            v-model="dates[1]"
            :max="$dayjs(new Date()).format('YYYY-MM-DD')"
            @input="checkOperation()" />
        </v-menu>
      </v-col>
      <v-spacer />
      <v-col :cols="isMobile ? '12' : 'auto'">
        <search-box
          v-model="searchInput"
          @on-search="onSearch()" />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <v-tabs
          v-model="tabs"
          show-arrows
          @change="changeTab($event)">
          <v-tab
            v-for="(tabTitle, index) in tabsTitle"
            :key="`tabTitle-${index}`">
            <span class="mr-1">
              {{ tabTitle.label }} |
            </span>
            <span
              v-if="loadingCount"
              class="count-tab">
              <v-progress-circular
                indeterminate
                size="16" />
            </span>
            <span v-else>
              {{ tabTitle.count }}
            </span>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col :cols="12">
        <delivery-and-withdraw-notes-table
          to="DeliveryNote"
          :height="500"
          :loading="getting"
          :note-data="deliveryNotesData"
          :status="tabsTitle[tabs].label"
          :warehouse-id="warehouseSelected"
          :warehouse="warehouse"
          :type="'DeliveryNote'"
          :is-mobile="isMobile"
          @cancel="tryToUpdateNote($event)"
          @renew="renewDeliveryNote($event)"
          @scroll="handleScroll($event)" />
      </v-col>
    </v-row>
    <v-dialog
      v-model="showPODialog"
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Enter Purchase Order No.</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent="getPurchaseOrder()">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="purchaseOrderId"
                    placeholder="POG2024XXXXXXX"
                    append-icon="mdi-magnify"
                    color="secondary"
                    outlined
                    hide-details
                    :loading="loading"
                    :disabled="loading"
                    @click:append="getPurchaseOrder()" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="togglePODialog()">
            Close
          </v-btn>
          <v-btn
            type="submit"
            color="green darken-1"
            text
            @click="getPurchaseOrder()">
            Submit
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { getAuthDecode, getRole } from '@/assets/js/Authentication'
import { mapActions, mapGetters } from 'vuex'
import DeliveryAndWithdrawNotesTable from '@/views/inventory/components/DeliveryAndWithdrawNotesTable.vue'
import SearchBox from '@/components/SearchBox.vue'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import InventoryProvider from '@/resources/InventoryProvider'
import AutoDeliveryNotesProvider from '@/resources/AutoDeliveryNotesProvider'
import PurchaseOrdersProvider from '@/resources/PurchaseOrdersProvider'

const InventoryService = new InventoryProvider()
const AutoDeliveryNotesService = new AutoDeliveryNotesProvider()
const PurchaseOrdersService = new PurchaseOrdersProvider()

export default {
  components: {
    deliveryAndWithdrawNotesTable: DeliveryAndWithdrawNotesTable,
    SearchBox,
    WarehouseSelect
  },
  data () {
    return {
      tabsTitle: [
        {
          label: 'pending',
          count: 0
        },
        {
          label: 'approved',
          count: 0
        },
        {
          label: 'canceled',
          count: 0
        },
        {
          label: 'draft',
          count: 0
        }
      ],
      operationItems: [
        'All',
        'Period'
      ],
      operation: 'All',
      searchInput: '',
      dates: [
        this.$dayjs().subtract(7, 'd').format('YYYY-MM-DD'),
        this.$dayjs().format('YYYY-MM-DD')
      ],
      menu: false,
      tabs: 0,
      page: 0,
      perPage: 15,
      totalPage: 1,
      getting: false,
      isLoadComplete: false,
      deliveryNotesData: [],
      autoDeliveryNotes: [],
      warehouseSelected: null,
      noteStatus: '',
      loadingCount: false,
      showPODialog: false,
      purchaseOrderId: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      warehouse: 'Store/warehouse',
      mapWarehouse: 'Store/mapWarehouse'
    }),
    enableDateRange () {
      return this.operation === 'Period'
    },
    dateRangeText () {
      return this.sortDate()
    },
    startDate () {
      if (this.enableDateRange) {
        const dates = this.sortDate()
        return dates[0] ? new Date(parseInt(this.$dayjs(dates[0], 'DD MMM YYYY').format('x'))) : null
      }
      return null
    },
    endDate () {
      if (this.enableDateRange) {
        const dates = this.sortDate()
        return dates[1] ? new Date(parseInt(this.$dayjs(dates[1], 'DD MMM YYYY').add(1, 'day').subtract(1, 'millisecond').format('x'))) : null
      }
      return null
    },
    isDisabledByRole () {
      const dnRole = [
        'management',
        'area_manager',
        'marketing',
        'developer',
        'creative',
        'vm',
        'merchandising_planner',
        'merchandiser',
        'online_admin',
        'accounting_manager',
        'warehouse_manager',
        'warehouse',
        'store_manager',
        'acting_assist_store_manager',
        'assist_store_manager',
        'sales_staff'
      ]
      const role = getRole()
      return !dnRole.some((r) => r === role)
    },
    isDisabled () {
      return this.warehouseSelected === 0
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },
    warehouses () {
      return this.mapWarehouse.filter((v) => v.id !== 0)
    }
  },
  watch: {
    warehouseSelected () {
      this.resetFetchDeliveryNote(true)
    },
    warehouses (val) {
      if (!this.warehouseSelected && val.length >= 1) {
        this.warehouseSelected = this.warehouses[0].id
      }
    }
  },
  mounted () {
    if (!this.warehouseSelected && this.warehouses.length >= 1) {
      this.warehouseSelected = this.warehouses[0].id
    }

    this.noteStatus = this.tabsTitle[this.tabs].label
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage'
    }),
    handleScroll (event) {
      const e = event.target
      const scrollY = e.scrollHeight - e.clientHeight - 100
      if (e.scrollTop >= scrollY && !this.isLoadComplete && !this.getting) {
        this.fetchData()
      }
    },
    checkOperation () {
      if (this.operation === 'All') {
        this.resetFetchDeliveryNote()
      } else if (this.operation === 'Period' && this.startDate && this.endDate) {
        this.resetFetchDeliveryNote()
      }
    },
    async renewDeliveryNote (index) {
      const deliveryNote = this.deliveryNotesData[index]
      let isDone = false
      try {
        this.deliveryNotesData.splice(index, 1, {
          ...deliveryNote,
          loading: true
        })
        const deliveryNoteById = await InventoryService.getDeliveryNoteById(deliveryNote.id)
        const createNote = await InventoryService.createDeliveryNotes({
          warehouse: deliveryNoteById.data.warehouse,
          products: deliveryNoteById.data.products,
          note: `Renew (${deliveryNote.code}) by ${getAuthDecode().name}`,
          status: 'draft'
        })
        isDone = createNote.data.status === 'draft'
      } catch (error) {
        console.error('cancelDeliveryNote', error)
      } finally {
        if (isDone) {
          await this.fetchCount()
        }
        this.deliveryNotesData.splice(index, 1, {
          ...deliveryNote
        })
      }
    },
    async tryToUpdateNote (index) {
      this.$store.dispatch('Components/setModal', {
        value: true,
        title: `ยืนยันการยกเลิก ${this.deliveryNotesData[index].code}`,
        message: 'การยกเลิกใบนำสินค้าเข้า(DN) จะไปยกเลิกใบนำสินค้าออก(WN)ด้วย โปรดตรวจสอบให้ถูกต้องก่อนการกดยกเลิก (ประวัติของคุณจะถูกเก็บไว้ในระบบ)',
        confirmText: 'Cancel',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Back',
        onConfirm: () => this.cancelDeliveryNote(index)
      })
    },
    async cancelDeliveryNote (index) {
      const deliveryNote = this.deliveryNotesData[index]
      let isDone = false
      try {
        this.deliveryNotesData.splice(index, 1, {
          ...deliveryNote,
          loading: true
        })
        const { data } = await InventoryService.updateStatusDeliveryNotes({
          id: deliveryNote.id,
          status: 'cancel'
        })

        isDone = data.status === 'canceled'

        if (deliveryNote?.withdrawNoteRef?.id) {
          await InventoryService.updateStatusWithdrawNotes({
            id: deliveryNote.withdrawNoteRef.id,
            status: 'cancel'
          })
        }
      } catch (error) {
        console.error('cancelDeliveryNote', error)
      } finally {
        if (isDone) {
          await this.fetchCount()
          this.deliveryNotesData.splice(index, 1)
        } else {
          this.deliveryNotesData.splice(index, 1, {
            ...deliveryNote
          })
        }
      }
    },
    sortDate () {
      const dates = []
      this.dates = this.dates.sort((a, b) => (this.$dayjs(a, 'YYYY-MM-DD').format('x') - this.$dayjs(b, 'YYYY-MM-DD').format('x')))
      for (let i = 0; i < this.dates.length; i++) {
        dates.push(this.$dayjs(this.dates[i], 'YYYY-MM-DD').format('DD MMM YYYY'))
      }
      return dates
    },
    fetchData () {
      this.fetchCount()
      this.fetchDeliveryNote()
    },
    async fetchCount () {
      try {
        const { data } = await InventoryService.getDeliveryNotesCount({
          search: this.searchInput,
          warehouse: this.warehouseSelected,
          startDate: this.startDate,
          endDate: this.endDate
        })
        for (let i = 0; i < this.tabsTitle.length; i++) {
          this.tabsTitle[i].count = data[this.tabsTitle[i].label]
        }
      } catch (error) {
        console.error('fetchCount', error)
      } finally {
        this.loadingCount = false
      }
    },
    changeTab (index) {
      // this.searchInput = ''
      this.noteStatus = this.tabsTitle[index].label
      this.resetFetchDeliveryNote()
    },
    resetFetchDeliveryNote (loadingCount = false) {
      this.loadingCount = loadingCount
      this.getting = false
      this.page = 0
      this.totalPage = 1
      this.isLoadComplete = false
      this.deliveryNotesData = []
      this.fetchData()
    },
    async fetchDeliveryNote () {
      this.getting = true
      if (this.page < this.totalPage) {
        this.page++
        try {
          const { data } = await InventoryService.getDeliveryNotes({
            page: this.page,
            limit: this.perPage,
            search: this.searchInput,
            status: this.noteStatus,
            warehouse: this.warehouseSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            sortBy: '_id',
            sortOrder: 'desc'
          })
          this.totalPage = data.pages
          this.deliveryNotesData.push(...data.results)

          const { data: response } = await AutoDeliveryNotesService.getAutoDeliveryNotes()
          this.autoDeliveryNotes = response
          this.mapAutoDeliveryNote()
        } catch (error) {
          console.error('fetchDeliveryNote', error)
          this.setErrorPage(error.code)
        } finally {
          this.isLoadComplete = this.page >= this.totalPage
          this.getting = false
        }
      }
    },
    mapAutoDeliveryNote () {
      this.deliveryNotesData.forEach((note) => {
        const tmpNote = note
        const tmpDelivery = this.autoDeliveryNotes.find((n) => n.deliveryNoteId === note.id)
        if (tmpDelivery) {
          tmpNote.autoDeliveryNoteId = tmpDelivery.deliveryNoteId
        } else {
          tmpNote.autoDeliveryNoteId = undefined
        }
      })
    },
    onSearch () {
      this.resetFetchDeliveryNote()
    },
    async getPurchaseOrder () {
      if (!this.purchaseOrderId) {
        return
      }

      try {
        this.loading = true

        const { data } = await PurchaseOrdersService.getItemByOrderId(this.purchaseOrderId)

        if (data && ['approved', 'received'].includes(data?.state)) {
          const foundWarehouse = this.warehouse.find((w) => w.code === data.locationCode)

          if (!foundWarehouse) {
            throw Error('Warehouse not found.')
          }

          const warehouse = data.type === 'RM' ? this.warehouseSelected : foundWarehouse.id

          const routeData = this.$router.resolve({ name: 'DeliveryNote', query: { poNo: data.orderNo, warehouse } })
          window.open(routeData.href, '_blank')
        } else if (data && !(['approved', 'received'].includes(data?.state))) {
          this.setSnackbar({
            value: true,
            message: 'PO ใบนี้ยังไม่ได้รับการอนุมัติ กรุณาติดต่อผู้ที่เกี่ยวข้อง',
            type: 'error'
          })
        } else {
          this.setSnackbar({
            value: true,
            message: 'Purchase order not found.',
            type: 'error'
          })
        }
      } catch (error) {
        console.error('getPurchaseOrder', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    togglePODialog () {
      this.showPODialog = !this.showPODialog
    }
  }
}
</script>

<style lang="scss" scoped>
#stock-movement {
  height: calc(100vh - 68px);
}
.movement-operation {
  width: 110px;
}
</style>
